import React from 'react';
import { TextField,InputAdornment } from "@material-ui/core";
import { Search as SearchIcon } from '@material-ui/icons';

const Searchbox = ({ onChange, placeholder }) => {
  return (
    <TextField 
      style={{marginTop: 10}}
      fullWidth
      type="search" 
      variant="outlined"
      placeholder={placeholder}
      onChange={e => {
        onChange(e);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Searchbox;