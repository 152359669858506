import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Container, IconButton, Box, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/add';
import DeleteIcon from '@material-ui/icons/delete';
import EditIcon from '@material-ui/icons/Edit';
import Alert from '../../components/Alert';
import Services from '../../services';
import Loader from '../../components/Loader';
import Table from '../../components/Table';
import DeleteDialog from '../../components/DeleteDialog';
import UsersDialog from './UsersDialogView';

const header = [
  {
    prop: 'displayName',
    name: 'Nombre'
  },
  {
    prop: 'email',
    name: 'Correo electrónico'
  },
  
];

const UsersView = () => {
  useFirestoreConnect([
    {collection: "users", orderBy: ['registry', 'desc']},
    {collection: "districts", orderBy: ['name']}
  ]);

  const users = useSelector(state => state.firestore.ordered.users);
  const districtsSelector = useSelector(state => state.firestore.ordered.districts);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [error , setError] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [user, setUser] = useState(null);
  const [saving, setSaving] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [federalDistricts, setFederalDistricts] = useState([]);
  const [message, setMessage] = useState("");
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if(districtsSelector && districtsSelector.length) {
      setDistricts(districtsSelector.filter(d => d.type === 2));
      setFederalDistricts(districtsSelector.filter(d => d.type === 1));
    }
  }, [districtsSelector])
  
  if(!isLoaded(users) || !isLoaded(districtsSelector)) {
    return <Loader/>;
  }

  const add = async () => {
    if(saving) return;

    try {
      setSaving(true);

      const _user = {...user};
      const sectionIds = user.sections.length ? user.sections.map(s => (s.id)) : [];

      delete _user.confirmPassword;
      delete _user.sections;

      const response = await Services.post("add-user", {
        ..._user,
        sectionIds: sectionIds
      });
      
      if(response.data.success) {
        setMessage("Usuario guardado con éxito.");
        setOpen(false);
        setTimeout(() => {
          setMessage("");
        }, 4000);
      } else if(response.data.error.code === "auth/email-already-exists") {
        setError('Error al guardar el usuario, el "Correo electrónico" está en uso.');
      }
      else {
        setError("Error al guardar el usuario, intentelo de nuevo.");
      }
    } catch (error) {
      console.log(error); 
      setError("Error al guardar el usuario, intentelo de nuevo.");
    } finally{
      setSaving(false);
    }
  }

  const update = async () => {
    if(saving) return;

    try {
      setSaving(true);

      const _user = {...user};
      const sectionIds = user.sections.length ? user.sections.map(s => (s.id)) : [];
      
      delete _user.confirmPassword;
      delete _user.sections;
      
      const response = await Services.post("update-user", {
        ..._user,
        sectionIds: sectionIds
      });

      if(response.data.success) {
        setMessage("Usuario actulizado con éxito.");
        setOpen(false);
        setTimeout(() => {
          setMessage("");
        }, 4000);
      } else if(response.data.error.code === "auth/email-already-exists") {
        setError('Error al editar el usuario, el "Correo electrónico" está en uso.');
      }
      else {
        setError("Error al editar el usuario, intentelo de nuevo.");
      }
    } catch (error) {
      console.log(error); 
      setError("Error al editar usuario, intentelo de nuevo");
    } finally{
      setSaving(false);
    }
  }

  const del = async () => {
    try {
      setDeleting(true);

      const response = await Services.post("delete-user", {
        "id": deleteId
      });

      if(!response.data.success) {
        setError("Error al borrar el usuario, intentelo de nuevo.")
      }
    } catch (error) {
      console.log(error);
      setError("Error al borrar el usuario, intentelo de nuevo.")
    } finally {
      setDeleteId("");
      setDeleting(false);
    }
  }

  return (
    <Container style={{marginTop: 30, paddingBottom: 60}}>
      <div style={{ width: '100%' }}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h5">Usuarios</Typography>
          </Box>
          <Box>
            <IconButton 
              onClick={() => {
                setOpen(true); 
                setMode("add"); 
                setUser({
                  displayName: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                  federalDistrictId: "",
                  districtIds: [],
                  sections: [],
                });
              }} 
              style={{backgroundColor: "#ef4036", color: "white", float: "right"}}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      </div>
      <Table 
        data={users}
        filter={["displayName", "email"]}
        header={header}
        paginated
        extraRows={[
          {
            prop: 'registry',
            name: 'Fecha de registro',
            cell: row => (
              <>{moment(Date.parse(row.registry.toDate())).format('DD-MM-YYYY HH:mm')}</> 
            )
          }, 
          {
            prop: 'del',
            name: 'Eliminar',
            cell: row => (
              <IconButton onClick={() => setDeleteId(row.id)}>
                <DeleteIcon/>
              </IconButton>
            )
          }, 
          {
            prop: 'edit',
            name: 'Editar',
            cell: row => (
              <IconButton onClick={async () => {
                let _row = {...row};
                let sections = [];

                if(_row.sectionIds && _row.sectionIds.length) {
                  for (let i = 0; i < _row.sectionIds.length; i++) {
                    const docSection = await Services.getDocumentById("sections", _row.sectionIds[i]);

                    sections.push({
                      ...docSection.data(),
                      id: docSection.id
                    });
                  }
                }
                
                _row.sections = sections;

                delete _row.sectionIds;

                setUser(_row);
                setOpen(true);
                setMode("edit");
              }}>
                <EditIcon/>
              </IconButton>
            )
          },
        ]}
      />
      <Alert 
        open={Boolean(error)}
        onClose={() => setError("")}
        message={error}
        severity="error"
      />
      <Alert 
        open={Boolean(message)}
        onClose={() => setMessage("")}
        message={message}
        severity="success"
      />
      <DeleteDialog 
        open={Boolean(deleteId)}
        onCancel={() => setDeleteId("")}
        onClose={() => setDeleteId("")}
        onAccept={del}
        loading={deleting}
      /> 
      <UsersDialog 
        saving={saving}
        open={open}
        user={user}
        onClose={() => setOpen(false)}
        onChange={(key, value) => {
          if(key === "federalDistrictId") {
            setUser({...user, federalDistrictId: value, districtIds: [], sections : []});
          }
          else if(key === "districtIds") {
            setUser({...user, districtIds: value, sections : []});
          }
          else {
            setUser({...user, [key]: value});
          } 
        }}
        districts={districts}
        federalDistricts={federalDistricts}
        onErrorPasswords={(_error) => setError(_error)}
        onAccept={mode === "add" ? add : update}
        mode={mode}
      />
    </Container>
  )
}

export default UsersView;  