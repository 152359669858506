//import asyncComponent from '../components/AsyncComponent';
import HomeView from './HomeView/HomeView';
import LogInView from './LoginView/index';
import NoticePrivacy from './noticePrivacy/NoticePrivacyView';
import NoticesView from './NoticesView/NoticesView';
import UsersView from './UsersView/UsersView';
import ManualsView from './ManualsView/ManualsView';


export { 
    HomeView, LogInView, NoticePrivacy, NoticesView, UsersView, ManualsView
};
