import React from 'react';
import 'typeface-roboto';
import { Button, Grid, Box  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MeetingRoom } from '@material-ui/icons';
import { Link, NavLink } from 'react-router-dom';
import logo from './../../white-logo.png';
import UserDetail from './UserDetail';
import { withRouter } from 'react-router';
import MobileHeader from './MobileDrawer';
import { Home } from '@material-ui/icons';
import firebase from 'firebase/app';
import PieChartIcon from '@material-ui/icons/PieChart';
import SearchIcon from '@material-ui/icons/Search';
import InputIcon from '@material-ui/icons/Input';
import DescriptionIcon from '@material-ui/icons/Description';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import AppsIcon from '@material-ui/icons/Apps';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import WarningIcon from '@material-ui/icons/Warning';

const links = [
  { to: '/', text: 'Inicio', icon: Home }, 
  { to: '/estadisitica', text: 'Estadistica', icon: PieChartIcon }, 
  { to: '/buscador', text: 'Buscador', icon: SearchIcon }, 
  { to: '/avance', text: 'Avance', icon: InputIcon }, 
  { to: '/reportes', text: 'Reportes', icon: DescriptionIcon }, 
  { to: '/usuarios', text: 'Usuarios', icon: PermIdentityIcon }, 
  { to: '/administracion', text: 'Administración', icon: AssignmentIndIcon }, 
  { to: '/noticias', text: 'Noticias', icon: AnnouncementIcon }, 
  { to: '/grupos', text: 'Grupos', icon: AppsIcon }, 
  { to: '/manuales', text: 'Manuales', icon: ViewQuiltIcon }, 
  { to: '/validacion', text: 'Validación', icon: DoneOutlineIcon }, 
  { to: '/ayuda', text: 'Ayuda', icon: HelpOutlineIcon }, 
  { to: '/errores', text: 'Errores visita', icon: WarningIcon }, 
];

const useStyles = makeStyles(theme => ({
  root: {
    height: 80,
    flexGrow: 1,
    textTransform: 'uppercase',
    backgroundColor: '#fafafa',
    position: 'relative',
    zIndex: '999',
    boxShadow: '-1px -3px 18px 6px rgba(0,0,0,0.12)',
    '& > *': {
      height: '100%'
    },
    '& img': {
      width: '150px'
    }
  },
  userOptions: {
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  mobileButton: {
    display: 'flex'
  },
  link: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    textDecoration: 'none',
    fontFamily: 'roboto',
    color: '#575555',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.07)'
    }
  },
  active: {
    backgroundColor: 'rgba(0,0,0,0.07)'
  },
  icon: {
    color: '#575555'
  }
}));

const getNavLinks = (links, classes) => {
  return links.map(link => {
    return (
      <NavLink
        exact={Boolean(link.to === '/')}
        key={link.to}
        to={link.to}
        activeClassName={classes.active}
        className={classes.link}>
        { link.text }
      </NavLink>
    )}
  )
}

const Header = ({ location, hideHeaderLinks }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}> 
      <Grid container style={{height: 80}}>
        <Grid xs={1}
          className={classes.mobileButton}
          item
          container
          justify="center">
            <MobileHeader links={links}/>
        </Grid>
        <Grid item xs={2}>
          <Link to="/">
            <img src={logo} style={{objectFit: "contain", height: 80}}/>
          </Link>
        </Grid>
        <Grid
          xs={7}
          item 
          container 
          direction="row" 
          justify="flex-start" 
          alignItems="center">
          { 
            hideHeaderLinks?
              null 
              : getNavLinks(links, classes) 
          }
        </Grid>
        <Grid xs={2}
          className={classes.userOptions}
          item >
          <Grid 
            container 
            direction="row" 
            justify="flex-end" 
            alignItems="center">
            <UserDetail 
              active={location && location.pathname.includes('profile') || location.pathname.includes('administracion')}
            />
            <Button>
              <Link 
                onClick={async () => {
                await firebase.logout();
                }} 
                to="/login">
                <MeetingRoom className={classes.icon}/>
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default withRouter(Header);