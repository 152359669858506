import React from 'react';
import Loader from './../components/Loader';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { isLoaded, isEmpty } from 'react-redux-firebase';

const RouteLayout = ({ component: Component, requireAuth, ...rest }) => {
  const auth = useSelector(state => state.firebase.auth);

  if (!isLoaded(auth)) {
    return <Loader/>;
  }

  return (
    <Route
      { ...rest }
      render={matchProps => {
        if (requireAuth && isEmpty(auth)) {
          return <Redirect to="/login"/>;
        }
        return <Component {...matchProps} />;
      }}
      />
  );
};

export default RouteLayout;