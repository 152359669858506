import React from 'react';
import { 
  Button, Dialog, DialogActions, DialogContent, DialogContentText, 
  DialogTitle, CircularProgress
} from '@material-ui/core';

const DeleteDialog = ({ open, onClose, onCancel, onAccept, loading }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
   >
      <DialogTitle >¡Advertencia!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Seguro que desea eliminar este registro?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancelar
        </Button>
        <Button 
          onClick={() => {
            if(loading) return;
            onAccept();
          }} 
          color="secondary"
          variant="contained" 
          autoFocus
        >
        {  
          loading
          ?
            <CircularProgress style={{color: "white"}} size={25} /> 
          : 
            "Aceptar" 
        }
        </Button>
      </DialogActions>
  </Dialog>
  );
};

export default DeleteDialog;