import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';

const config = {
  apiKey: "AIzaSyApCrLcyNVrbfDbGAuFuJ-P6K2qOM4UU-w",
  authDomain: "impelapp-d6985.firebaseapp.com",
  databaseURL: "https://impelapp-d6985.firebaseio.com",
  projectId: "impelapp-d6985",
  storageBucket: "impelapp-d6985.appspot.com",
  messagingSenderId: "890577292394",
  appId: "1:890577292394:web:6047639399697fb4fece13",
  measurementId: "G-MV8GQS6K7Z"
};

firebase.initializeApp(config);
firebase.firestore();

export default firebase;