import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, CircularProgress, DialogTitle, Typography, TextField } from '@material-ui/core';

const HomeDialogView = ({open, onCancel, onAccept, onChange, loading, report = {}, mode }) => {
  const [invalidFields,  setInvalidFields] = useState([]);

  const handleChange = event => {
    const { name, value, files } = event.target;

    if(name != 'pdf') {
      const modifiedReport = { ...report, [name]: value };
      onChange(modifiedReport);
    }
    else {
      const modifiedReport = { ...report, pdf: files[0], pdfName: files[0].name};
      onChange(modifiedReport);
    }
  }

  const handleBeforeSubmit = () => {
    let invalidFields = [];

    if (!report.name) {
      invalidFields.push('name');
    }
    if (!report.pdfName && mode === "add") { 
      invalidFields.push('pdfName'); 
    }
   
    setInvalidFields(invalidFields);

    if(!invalidFields.length) {
      onAccept();
    }
  }

  return (
    <Dialog 
      fullWidth
      open={open}
    >
      <DialogTitle>{mode == 'add' ? 'Nuevo reporte' : 'Editar reporte'}</DialogTitle>
      <DialogContent>
        <TextField variant="filled" fullWidth required onChange={handleChange} 
          value={report.name} name='name' label="Nombre del reporte" 
          error={invalidFields.includes('name')}
          helperText={'El "Nombre del reporte" es requerido.'}
        />
        <Button variant="contained" color="secondary" component="label">
          PDF
          <input type="file" accept="application/pdf" name="pdf"
            style={{ display: "none" }} onChange={handleChange}
          />
        </Button> 
        <Typography style={{fontSize: 12, color: !invalidFields.includes('pdfName') ? 'grey' : 'red'}}>
          {report.pdfName ? report.pdfName : 'Favor de subir un PDF. *'}
        </Typography>  
      </DialogContent>
      <DialogActions>
        <Button 
          variant="contained"
          color="secondary"
          onClick={() => {
            setInvalidFields([]);
            onCancel();
          }} 
        >
          Cancelar
        </Button>
        {
          loading
          ?
            <CircularProgress color="secondary" />
          :
            <Button 
              variant="contained"
              color="secondary" 
              onClick={handleBeforeSubmit} 
              autoFocus
            >
              Aceptar
            </Button>
        }
      </DialogActions>
  </Dialog>
  );
};

export default HomeDialogView;