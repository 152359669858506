import React, { useState } from 'react';
import moment from 'moment-timezone';
import firebase from 'firebase/app';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Container, IconButton, Box, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/add';
import DeleteIcon from '@material-ui/icons/delete';
import EditIcon from '@material-ui/icons/Edit';
import Alert from '../../components/Alert';
import Services from '../../services';
import Loader from '../../components/Loader';
import Table from '../../components/Table';
import DeleteDialog from '../../components/DeleteDialog';
import NoticesDialog from './NoticesDialogView';

const header = [
  {
    prop: 'name',
    name: 'Nombre'
  },
  {
    prop: 'description',
    name: 'Descripción'
  },
];

const NoticesView = () => {
  useFirestoreConnect([
    {collection: "notices", orderBy: ['registry', 'desc']}
  ]);
  const notices = useSelector(state => state.firestore.ordered.notices);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [error , setError] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [notice, setNotice] = useState(null);
  const [imagesDeleteStorage, setImagesDeleteStorage] = useState([]);
  const [saving, setSaving] = useState(false);
  
  if(!isLoaded(notices)) {
    return <Loader/>;
  }

  const add = async () => {
    if(saving) return;

    try {
      setSaving(true);

      let images = [];

      if(notice.images.length) {
        const dateNow = Date.now().toString(); 

        for (let i = 0; i < notice.images.length; i++) {
          const image = notice.images[i];
          const url = await Services.uploadFile("noticias/" + dateNow + image.imagePath.name, image.imagePath);
          
          if(url !== "error") {
            images.push({
              imageUrl: url,
              imagePath: "noticias/" + dateNow + image.imagePath.name
            })
          }
        }
      }

      await Services.addDocument("notices", {
        ...notice,
        images: images,
        registry: firebase.firestore.Timestamp.fromDate(new Date()),
      });
    } catch (error) {
      console.log(error); 
      setError("Error al guardar la noticia, intentelo de nuevo.");
    } finally{
      setOpen(false);
      setSaving(false);
    }
  }

  const update = async () => {
    if(saving) return;

    try {
      setSaving(true);
      let images = [...notice.images];
      
      if(notice.images.length) {
        const dateNow = Date.now().toString(); 

        for (let i = 0; i < notice.images.length; i++) {
          const image = notice.images[i];

          if(image.imagePath instanceof File) {
            const url = await Services.uploadFile("notices/" + dateNow + image.imagePath.name, image.imagePath);

            if(url !== "error") {
              images[i] = {
                imageUrl: url,
                imagePath: "notices/" + dateNow + image.imagePath.name
              };
            }
            else {
              images.splice(i, 1);
            }
          }
        }
      }

      await Services.updateDocument("notices", notice.id, {
        ...notice,
        images: images,
      });

      if(imagesDeleteStorage.length) {
        for (let i = 0; i < imagesDeleteStorage.length; i++) {
          try {
            await Services.deleteFile(imagesDeleteStorage[i]);
          } catch (error) {
            console.log(error);
            continue;
          }
        }
      }
    } catch (error) {
      console.log(error); 
      setError("Error al editar noticia, intentelo de nuevo");
    } finally{
      setOpen(false);
      setSaving(false);
    }
  }

  const del = async () => {
    try {
      const _notice = notices.find(n => n.id === deleteId);
      await Services.delete("notices", deleteId);

      if(_notice && _notice.images.length) {
        for (let i = 0; i < _notice.images.length; i++) {
          const image = _notice.images[i];

          try {
            await Services.deleteFile(image.imagePath);
          } catch (error) {
            console.log(error);
            continue;
          }
        }
      }
    } catch (error) {
      console.log(error);
      setError("Error al borrar la noticia, intentelo de nuevo.")
    } finally {
      setDeleteId("");
    }
  }

  return (
    <Container style={{marginTop: 30}}>
      <div style={{ width: '100%' }}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h5">Noticias</Typography>
          </Box>
          <Box>
            <IconButton 
              onClick={() => {
                setOpen(true); 
                setMode("add"); 
                setNotice({
                  name: "",
                  description: "",
                  images: []
                });
              }} 
              style={{backgroundColor: "#ef4036", color: "white", float: "right"}}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      </div>
      
      <Table 
        data={notices}
        filter={["name"]}
        header={header}
        paginated
        extraRows={[
          {
            prop: 'registry',
            name: 'Fecha de registro',
            cell: row => (
              <>{moment(Date.parse(row.registry.toDate())).format('DD-MM-YYYY HH:mm')}</> 
            )
          }, 
          {
            prop: 'del',
            name: 'Eliminar',
            cell: row => (
              <IconButton onClick={() => setDeleteId(row.id)}>
                <DeleteIcon/>
              </IconButton>
            )
          }, 
          {
            prop: 'edit',
            name: 'Editar',
            cell: row => (
              <IconButton onClick={() => {
                setOpen(true);
                setMode("edit");
                setNotice(row);
              }}>
                <EditIcon/>
              </IconButton>
            )
          },
        ]}
      />

      <Alert 
        open={Boolean(error)}
        onClose={() => setError("")}
        message={error}
        severity="error"
      />
      <DeleteDialog 
        open={Boolean(deleteId)}
        onCancel={() => setDeleteId("")}
        onClose={() => setDeleteId("")}
        onAccept={del}
      /> 
      <NoticesDialog 
        open={open}
        onClose={() => setOpen(false)}
        onAccept={mode === "add" ? add : update}
        mode={mode}
        notice={notice}
        onChange={(prop, value) => {
          if(prop !== "images") {
            setNotice({...notice, [prop]: value});
          }
          else {
            let images = [...notice.images];
            
            for (let index = 0; index < value.length; index++) {
              images.push({
                imageUrl: URL.createObjectURL(value[index]),
                imagePath: value[index],
              })
            }

            setNotice({...notice, [prop]: images});
          }
        }}
        deleteImage={(index, image) => {
          if(typeof image.imagePath === 'string') {
            setImagesDeleteStorage([...imagesDeleteStorage, image.imagePath]);
          }
        
          setNotice({...notice, images: notice.images.filter((r, i) => i !== index)})
        }}
        saving={saving}
      />
    </Container>
  )
}

export default NoticesView;  