import React, { useState } from 'react';
import { 
  TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Grid, MobileStepper, IconButton, CircularProgress
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteIcon from '@material-ui/icons/delete';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 20,
    maxWidth: "300px",
    width: "300px",
    margin: "auto"
  },
  img: {
    maxHeight: 255,
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    objectFit: "cover",
  },
}));

const NoticesDialogView = ({open, onClose, onAccept, mode, notice = {}, onChange, saving, deleteImage}) => {
  const [errors, setErrors] = useState([]);
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = notice ? notice.images.length : 0;

  if(!notice) return null;

  const handleAccept = () => {
    if(saving) return;

    let _errors = [];

    if(!notice.name) {
      _errors.push("name");
    }

    if(!notice.description) {
      _errors.push("description");
    }

    setErrors(_errors);

    if(_errors.length) return;

    onAccept();
  }

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

  const handleStepChange = (step) => setActiveStep(step);

  return (
    <Dialog
      className={classes.dialog}
      maxWidth="md"
      open={open}
    >
      <DialogTitle>{mode === "add" ? "Agregar noticia" : "Editar noticia"}</DialogTitle>
      <DialogContent className={classes.dialog}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} sm={12}>
            <TextField 
              error={errors.includes("name")}
              fullWidth
              type="text"
              label="Nombre"
              variant="outlined"
              value={notice.name}
              onChange={(event) => onChange("name", event.target.value)}
              helperText={'Favor de llenar el campo "Nombre".'}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <TextField 
              error={errors.includes("description")}
              fullWidth
              type="text"
              multiline
              rows={4}
              label="Descripción"
              variant="outlined"
              value={notice.description}
              onChange={(event) => onChange("description", event.target.value)}
              helperText={'Favor de llenar el campo "Descripción".'}
            />
          </Grid>
        </Grid>
        <Button
          style={{marginTop: 10, float: "center"}}
          variant="contained"
          component="label"
          color="secondary"
        >
          Subir imagenes
          <input
            multiple
            type="file"
            hidden
            onChange={(event) => {
              if(event.target.files && event.target.files.length) {
                onChange("images", event.target.files)
              }
            }}
            accept="image/*" 
          />
        </Button>           
        {
          notice.images.length 
          ?
            <div className={classes.root}>
              <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                interval={10000}
              >
              {notice.images.map((image, index) => (
                <div key={index}>
                  <IconButton 
                    style={{backgroundColor: "#049bbb", marginLeft: 52, marginBottom: -30,  color: "white"}}
                    onClick={() => {
                      if(activeStep !== 0) {
                        handleBack();
                      }
                      deleteImage(index, image);
                    }}
                  >
                    <DeleteIcon/>
                  </IconButton>
                  <div>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <img className={classes.img} src={image.imageUrl} />
                    ) : null}
                  </div>
                </div>
              ))}
              </AutoPlaySwipeableViews>
              <MobileStepper
                steps={maxSteps}
                position="static"
                variant="text"
                activeStep={activeStep}
                nextButton={
                  <Button size="small" endIcon={<ArrowForwardIosIcon/>} onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                    Siguiente
                  </Button>
                }
                backButton={
                  <Button size="small" startIcon={<ArrowBackIosIcon/>} onClick={handleBack} disabled={activeStep === 0}>
                    Atras
                  </Button>
                }
              />
            </div>
          : 
            <Typography align="center" style={{marginTop: 20}}>Sin imagenes...</Typography>
        }
      </DialogContent>
      <DialogActions style={{marginTop: 15}}>
        <Button 
          variant="contained"
          onClick={() => {
            setActiveStep(0);
            onClose();
          }}
        >
          Cancelar
        </Button>
        <Button 
          onClick={handleAccept} 
          variant="contained"
          color="secondary"
        >
        {
          saving 
          ?
            <CircularProgress size={25} style={{color: "white"}}/> 
          :
            "Guardar"
        }
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NoticesDialogView;
