import React, { useEffect, useState } from 'react';
import { 
  TextField, Dialog, DialogTitle, DialogContent, DialogActions, FormHelperText,
  Button, Grid, CircularProgress, MenuItem, Select, FormControl, InputLabel, Chip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Services from '../../services';

const useStyles = makeStyles(() => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validateEmail = email => 
   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email.toLowerCase());

const UsersDialogView = ({open, onClose, onAccept, mode, user = {}, onChange, saving, federalDistricts = [], districts = [], onErrorPasswords}) => {
  const classes = useStyles();
  const [errors, setErrors] = useState([]);
  const [sections, setSections] = useState([]);
  
  useEffect(() => {
    const getData = async () => {
      if(user && user.districtIds && user.districtIds.length) {
        const districtIds = user.districtIds;
        let _sections = [];
  
        if(districtIds.length) {
          const alldocsSections = [];
    
          for (let i = 0; i < districtIds.length; i++) {
            const documentsSections = await Services.getCollectionWhere("sections", "idDistrito", districtIds[i]);
            
            if(!documentsSections.empty) {
              alldocsSections.push(documentsSections.docs);
            }
          }
    
          if(alldocsSections.length) {
            for (let i = 0; i < alldocsSections.length; i++) {
              const s = alldocsSections[i].map((doc) => ({...doc.data(), id: doc.id}));
    
              _sections = [..._sections, ...s];
            }
          }
        }

        setSections(_sections);
      }
    }
    
    getData();
  }, [user])

  if(!user) return null;

  const handleAccept = () => {
    if(saving) return;

    let _errors = [];

    if(!validateEmail(user.email)) {
      _errors.push("email");
    }

    if((user.password && mode === "edit" || user.confirmPassword && mode === "edit") || mode === "add") {
      if(user.password.length < 7 || user.confirmPassword.length < 7) {
        _errors.push("passwords");
        onErrorPasswords("La contraseña debe tener un mínimo de 8 caracteres.");
      }

      if(user.password !== user.confirmPassword) {
        _errors.push("passwords");
        onErrorPasswords("Las contraseñas no coinciden.");
      }
    }

    if(!user.displayName) {
      _errors.push("displayName");
    }

    setErrors(_errors);

    if(_errors.length) return;

    onAccept();
  }

  const onChangeDistrict = async (value) => {
    const districtIds = value;
    let _sections = [];

    if(districtIds.length) {
      const alldocsSections = [];

      for (let i = 0; i < districtIds.length; i++) {
        const documentsSections = await Services.getCollectionWhere("sections", "idDistrito", districtIds[i]);
        
        if(!documentsSections.empty) {
          alldocsSections.push(documentsSections.docs);
        }
      }

      if(alldocsSections.length) {
        for (let i = 0; i < alldocsSections.length; i++) {
          const s = alldocsSections[i].map((doc) => ({...doc.data(), id: doc.id}));

          _sections = [..._sections, ...s];
        }
      }
    }

    setSections(_sections);
    onChange("districtIds", districtIds);
  }

  return (
    <Dialog
      maxWidth="md"
      open={open}
    >
      <DialogTitle>{mode === "add" ? "Agregar usuario" : "Editar usuario"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={6} sm={6}>
            <TextField 
              error={errors.includes("displayName")}
              fullWidth
              type="text"
              label="Nombre"
              variant="outlined"
              value={user.displayName}
              onChange={(event) => onChange("displayName", event.target.value)}
              helperText={'Favor de llenar el campo "Nombre".'}
            />
          </Grid>
          <Grid item md={6} xs={6} sm={6}>
            <TextField 
              error={errors.includes("email")}
              fullWidth
              type="text"
              label="Corre electrónico"
              variant="outlined"
              value={user.email}
              onChange={(event) => onChange("email", event.target.value)}
              helperText={'Favor de escribir un "Corre electrónico" válido.'}
              inputProps={{autoComplete: 'new-password'}}
            />
          </Grid>
          <Grid item md={6} xs={6} sm={6}>
            <TextField 
              error={errors.includes("password")}
              fullWidth
              inputProps={{autoComplete: 'new-password'}}
              type="password"
              label="Contraseña"
              variant="outlined"
              value={user.password}
              onChange={(event) => onChange("password", event.target.value)}
              helperText={'Favor de llenar el campo "Contraseña".'}
            />
          </Grid>
          <Grid item md={6} xs={6} sm={6}>
            <TextField 
              error={errors.includes("confirmPassword")}
              fullWidth
              type="password"
              label="Confirmar contraseña"
              variant="outlined"
              value={user.confirmPassword}
              onChange={(event) => onChange("confirmPassword", event.target.value)}
              helperText={'Favor de llenar el campo "Confirmar contraseña".'}
            />
          </Grid>
          <Grid item md={6} xs={6} sm={6}>
            <FormControl fullWidth error={errors.includes("federalDistrictId")}>
              <InputLabel>Distritos federales</InputLabel>
              <Select
                style={{marginTop: "17px"}}
                value={user.federalDistrictId}
                onChange={(event) => onChange("federalDistrictId", event.target.value)}
              >
              {
                federalDistricts.map((fd) => (
                  <MenuItem key={fd.id} value={fd.id}>{fd.name} - {fd.description}</MenuItem>
                ))
              } 
              </Select>
              <FormHelperText>Favor de seleccionar un "Distrito federal".</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={6} sm={6}>
            <FormControl disabled={!user.federalDistrictId} fullWidth error={errors.includes("districtIds")}>
              <InputLabel >Distritos locales</InputLabel>
              <Select
                multiple
                value={user.districtIds}
                onChange={async (event) => await onChangeDistrict(event.target.value)}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={(districts.find(s => s.id === value)).name + " " + (districts.find(s => s.id === value)).description} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
              {
                districts.filter(d => d.idDistrito === user.federalDistrictId).map((d) => (
                  <MenuItem key={d.id} value={d.id}>{d.name} - {d.description}</MenuItem>
                ))
              } 
              </Select>
              <FormHelperText>Favor de seleccionar uno o varios "Distritos locales".</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
          <Autocomplete
            value={user.sections}
            multiple
            disabled={!user.districtIds.length}
            onChange={(event, newValue) => {
              if(newValue) {
                onChange("sections", newValue);
              }
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={option.id} label={option.name} {...getTagProps({ index })} />
              ))
            }
            options={sections.map((s) => ({id: s.id, name: s.name}))}
            getOptionLabel={(section) => section ? section.name + " - [" + section.id + "]" : ""}
            renderInput={(params) => <TextField fullWidth {...params} label="Secciones" />}
          />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{marginTop: 15}}>
        <Button 
          variant="contained"
          onClick={() => {
            onClose();
          }}
        >
          Cancelar
        </Button>
        <Button 
          onClick={handleAccept} 
          variant="contained"
          color="secondary"
        >
        {
          saving 
          ?
            <CircularProgress size={25} style={{color: "white"}}/> 
          :
            "Guardar"
        }
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UsersDialogView;