import firebase from 'firebase/app';
import axios from 'axios';

const baseUrl = "https://us-central1-impelapp-d6985.cloudfunctions.net/app/";

class Services {
  async getCollection(collection) {
    return await firebase.firestore().collection(collection).get();
  }

  async getCollectionWhere(collection, w1, w2) {
    return await firebase.firestore().collection(collection).where(w1, "==", w2).get();
  }

  async addDocument(collection, data) {
    return await firebase.firestore().collection(collection).add(data);
  }

  async getDocumentById(collection, id) {
    return await firebase.firestore().collection(collection).doc(id).get();
  }

  async getDocumentWhere(collection, w1, w2) {
    return await firebase.firestore().collection(collection).where(w1, "==", w2).limit(1).get();
  }

  async updateDocument(collection, id, data) {
    return await firebase.firestore().collection(collection).doc(id).update(data);
  }

  async uploadFile(path, _file) {
    try {
      const file = await firebase.storage().ref().child(path).put(_file);
      const fileUrl = await file.ref.getDownloadURL();
      
      return fileUrl.toString();
    } catch (error) {
      console.log(error);
      return 'error';
    }
  }

  async deleteFile(path) {
    return await firebase.storage().ref().child(path).delete();
  }

  async delete(collection, id) {
    return await firebase.firestore().collection(collection).doc(id).delete();
  }

  async post(url = "", data = {}) {
    return await axios.post(baseUrl + url, data);
  }

  async get(url = "") {
    return await axios.get(baseUrl + url);
  }
}

export default new Services;