import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const ErrorAlert = ({ open, onClose, message, severity, ...rest }) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}>
      <Alert
        severity={severity}
        elevation={6}
        variant="filled"
        onClose={onClose}
        { ...rest }
        >
        { message }
      </Alert>
    </Snackbar>
  );
};

export default ErrorAlert;