import React, { useState, useCallback } from 'react';
import { 
  TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Grid, MobileStepper, Checkbox, Box, CircularProgress, ListItemText
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const useStyles = makeStyles(() => ({
  root: {
    maxWidth: "300px",
    width: "300px",
    margin: "auto"
  },
  img: {
    maxHeight: 255,
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    objectFit: "cover",
  },
  
}));

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 29.0572744,
  lng: -110.9560032
};

const HomeDialogView = ({sympathy, open, onClose}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = sympathy ? sympathy.images.length : 0;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyApCrLcyNVrbfDbGAuFuJ-P6K2qOM4UU-w"
  })

  if(!sympathy) return null;

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

  const handleStepChange = (step) => setActiveStep(step);
  
  return (
    <Dialog
      maxWidth="md"
      open={open}
    >
      <DialogTitle>Simpatía</DialogTitle>
      <DialogContent className={classes.dialog}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12} sm={12}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="textPrimary"
                  >
                  Sección
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    color="textPrimary"
                  >
                  {sympathy.sectionName}
                  </Typography>
                </React.Fragment>
              }
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="textPrimary"
                  >
                  INE
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    color="textPrimary"
                  >
                  {sympathy.ine}
                  </Typography>
                </React.Fragment>
              }
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
          <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="textPrimary"
                  >
                  Nombre
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    color="textPrimary"
                  >
                  {sympathy.name}
                  </Typography>
                </React.Fragment>
              }
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="textPrimary"
                  >
                  Apellido paterno
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    color="textPrimary"
                  >
                  {sympathy.lastName1}
                  </Typography>
                </React.Fragment>
              }
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="textPrimary"
                  >
                  Apellido materno
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    color="textPrimary"
                  >
                  {sympathy.lastName2}
                  </Typography>
                </React.Fragment>
              }
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="textPrimary"
                  >
                  Ocupación
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    color="textPrimary"
                  >
                  {sympathy.ocupation}
                  </Typography>
                </React.Fragment>
              }
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="textPrimary"
                  >
                  Colonia
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    color="textPrimary"
                  >
                  {sympathy.suburb}
                  </Typography>
                </React.Fragment>
              }
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="textPrimary"
                  >
                  Calle
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    color="textPrimary"
                  >
                  {sympathy.street}
                  </Typography>
                </React.Fragment>
              }
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="textPrimary"
                  >
                  Número
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    color="textPrimary"
                  >
                  {sympathy.number}
                  </Typography>
                </React.Fragment>
              }
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="textPrimary"
                  >
                  Código postal
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    color="textPrimary"
                  >
                  {sympathy.cp}
                  </Typography>
                </React.Fragment>
              }
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="textPrimary"
                  >
                  Correo electronico
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    color="textPrimary"
                  >
                  {sympathy.email}
                  </Typography>
                </React.Fragment>
              }
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="textPrimary"
                  >
                  Teléfono
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    color="textPrimary"
                  >
                  {sympathy.phone}
                  </Typography>
                </React.Fragment>
              }
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="textPrimary"
                  >
                  Comentarios
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    color="textPrimary"
                  >
                  {sympathy.comments}
                  </Typography>
                </React.Fragment>
              }
            />
          </Grid>
          <Grid item md={3} xs={12} sm={12}>
            <Typography align="center">Convencido</Typography>
            <Box display="flex" justifyContent="center">
              <Checkbox 
                disabled={true}
                checked={sympathy.convinced}
              />
            </Box>
          </Grid>
          {
            sympathy.convinced
            ?
              <>
                <Grid item md={3} xs={12} sm={12}>
                  <Typography align="center">Participación en las filas del partido.</Typography>
                  <Box display="flex" justifyContent="center">
                    <Checkbox 
                      disabled={true}
                      checked={sympathy.committee}
                    />
                  </Box>
                </Grid>
                <Grid item md={3} xs={12} sm={12}>
                  <Typography align="center">Participación el dia de la elección.</Typography>
                  <Box display="flex" justifyContent="center">
                    <Checkbox 
                      disabled={true}
                      checked={sympathy.participation}
                    />
                  </Box>
                </Grid>
                <Grid item md={3} xs={12} sm={12}>
                  <Typography align="center">Apoyo en las redes sociales Facebook y Twitter</Typography>
                  <Box display="flex" justifyContent="center">
                      <Checkbox 
                        disabled={true}
                        checked={sympathy.support}
                      />
                  </Box>
                </Grid>
            </>
          :
            null
        }
        <Grid item md={6} xs={12} sm={12}>
        {
          sympathy.images.length 
          ?
            <div className={classes.root}>
              <Typography>Evidencias</Typography>
              <br />
              <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                interval={10000}
              >
              {sympathy.images.map((image, index) => (
                <div key={index}>
                  <div>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <img className={classes.img} src={image.imageUrl} />
                    ) : null}
                  </div>
                </div>
              ))}
              </AutoPlaySwipeableViews>
              <MobileStepper
                steps={maxSteps}
                position="static"
                variant="text"
                activeStep={activeStep}
                nextButton={
                  <Button size="small" endIcon={<ArrowForwardIosIcon/>} onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                    Siguiente
                  </Button>
                }
                backButton={
                  <Button size="small" startIcon={<ArrowBackIosIcon/>} onClick={handleBack} disabled={activeStep === 0}>
                    Atras
                  </Button>
                }
              />
            </div>
          : 
            <Typography align="center">Sin evidencias...</Typography>
        }
        </Grid>
        <Grid item md={6} xs={12} sm={12}>
        {
          sympathy.firma && sympathy.firma.firmaUrl 
          ?
            <>
              <Typography>Firma</Typography>
              <br />
              <img height={200} width={300} src={sympathy.firma.firmaUrl } />
            </>
          :
            <Typography align="center">Sin Firma...</Typography>
        }
        </Grid>
        {
          isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
            >
              <Marker position={
                sympathy.geoPoint 
                ? 
                  {
                    lat: sympathy.geoPoint.latitude,
                    lng: sympathy.geoPoint.longitude
                  }
                : 
                  {
                    lat: 29.0572744,
                    lng: -110.9560032
                  }
              }/>
            </GoogleMap>
        ) : <CircularProgress />
        }

      </Grid>
      </DialogContent>
      <DialogActions style={{marginTop: 15}}>
        <Button 
          variant="contained"
          onClick={() => {
            setActiveStep(0);
            onClose();
          }}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default HomeDialogView;
