import React, { useState } from 'react';
import moment from 'moment-timezone';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Container, IconButton, TextField, Typography, Grid } from '@material-ui/core';
import Loader from '../../components/Loader';
import Table from '../../components/Table';
import VisibilityIcon from '@material-ui/icons/Visibility';
import HomeDialog from './HomeDialogView';
import Services from '../../services';

const header = [
  {
    prop: 'ine',
    name: 'INE'
  },
  {
    prop: 'name',
    name: 'Nombre'
  },
  {
    prop: 'lastName1',
    name: 'Apellido paterno'
  },
  {
    prop: 'lastName2',
    name: 'Apellido materno'
  },
];

const HomeView = () => {
  useFirestoreConnect([
    {collection: "sympathizers", orderBy: ['registry', 'desc']}
  ]);
  const sympathizers = useSelector(state => state.firestore.ordered.sympathizers);
  const [sympathy, setSympathy] = useState(null);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  if (!isLoaded(sympathizers)) {
    return <Loader/>;
  }

  return (
    <Container style={{marginTop: 30, paddingBottom: 60}}>
      <Grid container spacing={2}>
        <Grid item md={2} xs={12} sm={12}>
          <Typography variant="h5">Simpatías</Typography>
        </Grid>
        <Grid item md={4} xs={12} sm={12}>
        </Grid>
        <Grid item md={3} xs={5} sm={5}>
          <TextField 
            label="Inicio"
            onChange={(event) => setStart(event.target.value)}
            value={start}
            variant="outlined"
            fullWidth
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item md={3} xs={5} sm={5}>
          <TextField 
            
            label="Fin"
            onChange={(event) => {
              if(moment(event.target.value).format('YYYY-MM-DD') >= moment(start).format('YYYY-MM-DD')) {
                setEnd(event.target.value)
              }
            }}
            value={end}
            variant="outlined"
            fullWidth
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ 
              inputProps: { 
                min: moment(start).format('YYYY-MM-DD') 
              } 
            }}
          />
        </Grid>
      </Grid>
      <Table 
        data={
          sympathizers.filter(s => (!start || !end) 
            ? 
              true 
            : 
              moment(Date.parse(s.registry.toDate())).format('YYYY-MM-DD HH:mm') >= moment(start).format('YYYY-MM-DD HH:mm')
              && moment(Date.parse(s.registry.toDate())).format('YYYY-MM-DD HH:mm') <= moment(end).format('YYYY-MM-DD HH:mm')
          )
        }
        filter={["name", "ine", "lastName1", "lastName2"]}
        header={header}
        paginated
        extraRows={[
          {
            prop: 'registry',
            name: 'Fecha de registro',
            cell: row => (
              <>{moment(Date.parse(row.registry.toDate())).format('DD-MM-YYYY HH:mm')}</> 
            )
          }, 
          {
            prop: 'ver',
            name: '\u00A0\u00A0\u00A0Ver',
            cell: row => (
              <IconButton onClick={async () => {
                const section = await Services.getDocumentWhere("sections", "id", row.sectionId);
                setSympathy({...row, sectionName: section.docs[0].data()["name"]})
              }}>
                <VisibilityIcon/>
              </IconButton>
            )
          }
        ]}
      />
      <HomeDialog 
        open={Boolean(sympathy)}
        onClose={() => setSympathy(null)}
        sympathy={sympathy}
      />
    </Container>
  )
}

export default HomeView;  