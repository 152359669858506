import React, { useState } from 'react'
import Container from '../../components/Container';
import { Typography, IconButton, Box } from '@material-ui/core';
import Table from '../../components/Table';
import Loader from '../../components/Loader';
import DeleteDialog from '../../components/DeleteDialog';
import TableIconButton from '../../components/TableIconButton';
import ManualsDialog from './ManualsDialogView';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Services from '../../services';
import AddIcon from '@material-ui/icons/add';

const header = [
  {
    prop: 'name',
    name: 'Reporte'
  },
]

export default function ManualsView() {
  useFirestoreConnect([{collection: 'manuals', orderBy: ['name']}]);
  const manuals = useSelector(state => state.firestore.ordered.manuals);
  const [open, setOpen] = useState(false);
  const [report, setReport] = useState({});
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState();
  const [reportDelete, setReportDelete] = useState(null);

  if (!isLoaded(manuals)) {
    return <Loader />;
  }

  const openDialog = (mode, row) => {
    setMode(mode);
    setReport(row ? row :
      {
        name: '',
        pdf: '',
      }   
    );
    setOpen(true);
  }

  const onChange = _report => {
    setReport(_report);
  } 

  const add = async () => {
    setLoading(true);

    const now = Date.now().toString();
    const pdfUrl = await Services.uploadFile('manuals/'+ now + report.pdfName, report.pdf);
    
    if(pdfUrl === 'error') {
      console.error('Error al subir los archivos.');
    }
    else {
      Services.addDocument('manuals', {
        'name': report.name,
        'pdfUrl': pdfUrl,
        'pdfPath': 'manuals/' + now + report.pdfName,
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });
    }
  }

  const update = async () => {
    setLoading(true);

    let pdfUrl = '';
    let data = {
      'name': report.name,
    };

    if(report.pdf) {
      await Services.deleteFile(report.pdfPath);

      const now = Date.now().toString();
      pdfUrl = await Services.uploadFile('manuals/'+ now + report.pdfName, report.pdf);
      data.pdfUrl = pdfUrl;
      data.pdfPath = 'manuals/' + now + report.pdfName;
    }

    if(pdfUrl == 'error') {
      console.error('Error al subir los archivos.');
    }
    else {
      Services.updateDocument('manuals', report.id, data)
        .catch((e) => console.log(e))
        .finally(() => {
          setLoading(false);
          setOpen(false);
        });
    }
  }
  
  const deleteReport = async () => {
    await Services.deleteFile(reportDelete.pdfPath);
    await Services.delete('manuals', reportDelete.id);

    setReportDelete();
  }

  return (
    <>
    <Container>
      <div style={{ width: '100%' }}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography style={{color: 'grey', fontSize: 30}}>Manuales</Typography>
          </Box>
          <Box >
            <IconButton 
              onClick={() => openDialog('add', null)}  
              style={{backgroundColor: "#ef4036", color: "white", float: "right"}}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      </div>
      <Table 
        header={header}
        data={manuals}
        paginated
        filter={['name']}
        extraRows={[
          {
            prop: 'delete',
            name: 'Eliminar',
            cell: row => (
              <TableIconButton
                onClick={() => setReportDelete(row)}
                icon={DeleteIcon}
              />
            )
          },
          {
            prop: 'edit',
            name: 'Modificar',
            cell: row => (
              <TableIconButton
                onClick={() => openDialog('update', row)}
                icon={EditIcon}
              />
            )
          },
          {
            prop: 'toPDF',
            name: 'Ver PDF',
            cell: row => (
              <TableIconButton
                onClick={() => window.open(row.pdfUrl, '_blank')}
                icon={VisibilityIcon}
              />
            )
          }
        ]}
      />
    </Container>
    <ManualsDialog 
      open={open}
      onCancel={ () => setOpen(false)}
      onAccept={() => mode == 'add' ? add() : update()}
      onChange={onChange}
      loading={loading}
      report={report}
      mode={mode}
    />
    <DeleteDialog
      open={Boolean(reportDelete)}
      onAccept={deleteReport}
      onCancel={() => setReportDelete(null)}
    />
    </>
  )
}